import { Button, Modal } from "antd"
import React, { useState } from "react"
import Page from "../../../components/page"
import ResponsiveImageCarousel from "../../../components/responsive-image-carousel"
import ResponsiveImageGrid from "../../../components/responsive-image-grid"
import ScrollDown from "../../../components/scroll-down"
import { themes } from "../../../styles/themes"
import "../../../styles/variables.scss"
import paths from "../../../utils/paths"

const spaceAsAPleasureCarousel = labels => [
  {
    src: "/images/space-as-a-pleasure/the-deep-throat-piercing-detail.jpg",
    imgTitle: labels.deepThroatImgTitle,
    alt: labels.spaceAsAPleasureTheDeepThroatPiercingAlt,
    caption: labels.spaceAsAPleasurePassion
  },
  {
    src: "/images/space-as-a-pleasure/007-bar-detail.jpg",
    imgTitle: labels.double07BarImgTitle,
    alt: labels.spaceAsAPleasure007BarAlt,
    caption: labels.spaceAsAPleasureShakenNotStirred
  },
  {
    src: "/images/space-as-a-pleasure/easy-rider-detail.jpg",
    imgTitle: labels.easyRiderImgTitle,
    alt: labels.spaceAsAPleasureEasyRiderAlt,
    caption: labels.spaceAsAPleasureYipeee
  },
  {
    src: "/images/space-as-a-pleasure/mustang-reverted.jpg",
    imgTitle: labels.mustangImgTitle,
    alt: labels.spaceAsAPleasureMustangAlt,
    caption: labels.spaceAsAPleasureDownBoy
  },
  {
    src: "/images/space-as-a-pleasure/xxx-commode-detail.jpg",
    imgTitle: labels.xxxCommodeImgTitle,
    alt: labels.spaceAsAPleasureXXXCommodeAlt,
    caption: labels.spaceAsAPleasureDontBeShyBaby
  },
  {
    src: "/images/space-as-a-pleasure/madame-detail.jpg",
    imgTitle: labels.madameImgTitle,
    alt: labels.spaceAsAPleasureMadameAlt,
    caption: labels.spaceAsAPleasureSoftTruth
  },
  {
    src: "/images/space-as-a-pleasure/whiskey-side-table-detail.jpg",
    imgTitle: labels.whiskeySideTableImgTitle,
    alt: labels.spaceAsAPleasureWhiskeySideTableAlt,
    caption: labels.spaceAsAPleasureSmokinHot
  },
  {
    src: "/images/space-as-a-pleasure/coquette-odette-sette-detail.jpg",
    imgTitle: labels.coquetteOdetteImgTitle,
    alt: labels.spaceAsAPleasureCoquetteOdetteSetteAlt,
    caption: labels.spaceAsAPleasureLetMeSeduceYou
  },
  {
    src: "/images/space-as-a-pleasure/wake-up-for-make-up-detail.jpg",
    imgTitle: labels.wakeUpForMakeUpImgTitle,
    alt: labels.spaceAsAPleasureWakeUpForMakeUpAlt,
    caption: labels.spaceAsAPleasurePinchMe
  },
  {
    src: "/images/space-as-a-pleasure/the-deep-throat-lip-detail.jpg",
    imgTitle: labels.deepThroatImgTitle,
    alt: labels.spaceAsAPleasureTheDeepThroatLipAlt,
    caption: labels.spaceAsAPleasureLustForLife
  },
  {
    src: "/images/space-as-a-pleasure/boudoir-collection-detail.jpg",
    imgTitle: labels.boudoirCollectionImgTitle,
    alt: labels.spaceAsAPleasureBoudoirCollectionAlt,
    caption: labels.spaceAsAPleasureLetsStickTogether
  }
]

const spaceAsAPleasureGrid = (labels, pagePaths) => [
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/space-as-a-pleasure/from-purity-to-black-white.jpg",
        srcHover: "/images/space-as-a-pleasure/from-purity-to.jpg",
        link: pagePaths.fromPurityTo,
        alt: labels.spaceAsAPleasureFromPurityToAlt,
        showClickMe: true
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/space-as-a-pleasure/pleasure-empire-black-white.jpg",
        srcHover: "/images/space-as-a-pleasure/pleasure-empire.jpg",
        link: pagePaths.pleasureEmpire,
        alt: labels.spaceAsAPleasurePleasureEmpireToAlt,
        showClickMe: true
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/space-as-a-pleasure/im-the-wild-one-black-white.jpg",
        srcHover: "/images/space-as-a-pleasure/im-the-wild-one.jpg",
        link: pagePaths.imTheWildOne,
        alt: labels.spaceAsAPleasureImTheWildOneAlt,
        showClickMe: true
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/space-as-a-pleasure/flirt-with-art-black-white.jpg",
        srcHover: "/images/space-as-a-pleasure/flirt-with-art.jpg",
        link: pagePaths.flirtWithArt,
        alt: labels.spaceAsAPleasureFlirtWithArtAlt,
        showClickMe: true
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/space-as-a-pleasure/x-rated-black-white.jpg",
        srcHover: "/images/space-as-a-pleasure/x-rated.jpg",
        link: pagePaths.xRated,
        alt: labels.spaceAsAPleasureXRatedAlt,
        showClickMe: true
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/space-as-a-pleasure/dressed-to-impress-black-white.jpg",
        srcHover: "/images/space-as-a-pleasure/dressed-to-impress.jpg",
        link: pagePaths.dressedToImpress,
        alt: labels.spaceAsAPleasureDressedToImpressAlt,
        showClickMe: true
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/space-as-a-pleasure/good-vibrations-black-white.jpg",
        srcHover: "/images/space-as-a-pleasure/good-vibrations.jpg",
        link: pagePaths.goodVibrations,
        alt: labels.spaceAsAPleasureGoodVibrationsAlt,
        showClickMe: true
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/space-as-a-pleasure/back-room-black-white.jpg",
        srcHover: "/images/space-as-a-pleasure/back-room.jpg",
        link: pagePaths.backRoom,
        alt: labels.spaceAsAPleasureBackRoomAlt,
        showClickMe: true
      }
    ]
  }
]

export default function SpaceAsAPleasure({
  labels,
  history,
  location,
  ...props
}) {
  const [visible, setVisible] = useState(location.state?.showModal)
  if (typeof window !== "undefined") {
    window.history.replaceState({ showModal: false }, location.pathname)
  }
  const locationState = location.state !== undefined ? location.state : {}
  location.state = { ...locationState, showModal: false }

  const close = () => setVisible(false)

  return (
    <Page
      {...props}
      labels={labels}
      title={labels.spaceAsAPleasure}
      theme={themes(labels).ipsWhite(true)}
      description={labels.spaceAsAPleasureDescription}
      keywords={labels.spaceAsAPleasureKeywords}
      ogUrl={paths(labels).spaceAsAPleasure}
      ogImage="/images/og-images/space-as-a-pleasure.jpg"
      location={location}
      footerPadding
    >
      <Modal
        visible={visible}
        onCancel={close}
        getContainer={false}
        footer={null}
        className="space-as-a-pleasure-modal"
        closable={false}
        maskClosable={false}
        zIndex={30}
        maskStyle={{ backgroundColor: "rgba(255, 0, 0, 0.9)" }}
      >
        <p>
          <span className="space-as-a-pleasure-modal-bold-text">
            {labels.spaceAsAPleasureSpaceAsAPleasureText}
          </span>
          <span>{labels.spaceAsAPleasureAboutText}</span>
        </p>
        <br />
        <p>{labels.spaceAsAPleasureAuthorText}</p>
        <div className="space-as-a-pleasure-modal-enter-container">
          <Button className="space-as-a-pleasure-enter-button" onClick={close}>
            {labels.spaceAsAPleasureEnter}
          </Button>
        </div>
      </Modal>

      <ScrollDown />

      <ResponsiveImageCarousel
        images={spaceAsAPleasureCarousel(labels)}
        autoplay
        fade
        className="space-as-a-pleasure-carousel"
        showIndicators={false}
      />
      <ResponsiveImageGrid
        gridClass="space-as-a-pleasure-image-grid"
        grid={spaceAsAPleasureGrid(labels, paths(labels))}
      />
    </Page>
  )
}
